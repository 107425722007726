import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import { Endpoints } from "../../repository/Endpoints";
import { PostMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import { Language } from "./Language";
import Logout from "../../utils/sharedComponents/Logout";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
import MonthsTranslated from "./MonthsTranslated";
import validator from "email-validator";

const Userdata = () => {
  //inits
  const history = useHistory();
  const webcamRef = React.useRef(null);
  const [userphoto, setUserphoto] = useState("");
  const [day_val, setDayVal] = useState(1);
  const [month_val, setMonthVal] = useState(1);
  const [year_val, setYearVal] = useState(2000);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [userdob, setUserdob] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const language = localStorage.getItem("language");

  //logics
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUserphoto(imageSrc);
  }, [webcamRef]);

  const toggleVisibility = (id) => {
    window.scrollTo(0, 0);
    var jumbos = [
      "dob_jumbotron",
      "email_jumbotron",
      "company_jumbotron",
      "lastname_jumbotron",
      "firstname_jumbotron",
      "photo_jumbotron",
      "datepicker_jumbotron",
    ];

    for (let index = 0; index < jumbos.length; index++) {
      const jumbo = jumbos[index];
      var x = document.getElementById(jumbo);
      x.style.display = "none";
    }
    var x = document.getElementById(id);
    x.style.display = "block";
  };

  const onMonthChange = (value) => {
    setMonthVal(value);
    date_validity(value);
  };

  const date_validity = (month) => {
    var day = day_val;
    console.log(day, month);
    month = parseInt(month);
    day = parseInt(day);
    if (month === 4 || month === 6 || month === 9 || month === 11) {
      if (day > 30) {
        alert("invalid day selected for selected month");
      }
    } else if (month === 2) {
      if (day > 29) {
        alert("invalid day selected for selected month");
      }
    }
  };
  const set_date = () => {
    var day = parseInt(day_val);
    var month = parseInt(month_val);
    var month_name =
      document.getElementById("month_setter").options[month].text;
    var year = parseInt(year_val);
    var leap_year = year % 4;
    var allgood = true;
    if (day === "Day") {
      alert("select day");
      allgood = false;
    }
    if (month === "Month") {
      alert("select month");
      allgood = false;
    }
    if (day > 29 && month === "2") {
      alert("invalid date");
      allgood = false;
    }
    if (day === 29 && month === 2 && leap_year !== 0) {
      alert("invalid date");
      allgood = false;
    }
    if (
      day === 31 &&
      (month === 4 || month === 6 || month === 9 || month === 11)
    ) {
      alert("invalid date");
      allgood = false;
    }
    if (allgood) {
      console.log(
        `${month_name} ${
          day.toString().length === 1 ? "0" + day : day
        }, ${year}`
      );
      //console.log('day',day,'month',month,'year',year,'month_name',month_name);
      document.getElementById("userdob").value = `${month_name} ${
        day.toString().length === 1 ? "0" + day : day
      }, ${year}`;
      document.getElementById("userdob_val").value = `${year}-${
        month.toString().length === 1 ? "0" + month : month
      }-${day.toString().length === 1 ? "0" + day : day}`;
      toggleVisibility("dob_jumbotron");

      setUserdob(
        `${year}-${month.toString().length === 1 ? "0" + month : month}-${
          day.toString().length === 1 ? "0" + day : day
        }`
      );
    }
  };

  const createCalendar = () => {
    var d = new Date();
    var current_year = d.getFullYear();
    var month_arr = MonthsTranslated[localStorage.getItem("language")];
    for (let day = 1; day < 32; day++) {
      document.getElementById(
        "day_setter"
      ).innerHTML += `<option value="${day}">${day}</option>`;
    }
    for (let month = 1; month < 13; month++) {
      //console.log(`<option value="${month}">${month_arr[month]}</option>`);
      document.getElementById(
        "month_setter"
      ).innerHTML += `<option value="${month}">${month_arr[month]}</option>`;
    }
    for (let year = 1900; year < current_year; year++) {
      document.getElementById("year_setter").innerHTML += `<option ${
        year === 2000 ? "selected" : ""
      } value="${year}">${year}</option>`;
    }

    toggleVisibility("datepicker_jumbotron");
  };

  const storeData = (e) => {
    e.preventDefault();
    document.getElementById("confirm").innerHTML = "Wait...";
    console.log(
      "userdata",
      firstName,
      lastName,
      email,
      company,
      userdob,
      userphoto.substring(0, 30)
    );
    if (
      // userphoto !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      company !== "" &&
      userdob !== ""
    ) {
      localStorage.setItem("username", firstName + " " + lastName);
      localStorage.setItem("useremail", email);
      localStorage.setItem("usercompany", company);
      localStorage.setItem("userdob", userdob);
      // localStorage.setItem("userphoto", userphoto);
      PostMaker(
        {
          first_name: firstName,
          last_name: lastName,
          mobile: "01234",
          email: email,
          birth_date: userdob,
          lang: localStorage.getItem('language_id'),//2,
          trade: "tester",
          company: company,
          user_image:
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
          personal_code: "",
          tenant_id: localStorage.getItem("tenant_id"),
        },
        Endpoints.register
      ).then((res) => {
        if (res) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("user_id", res.user_id);
          localStorage.setItem("profile_id", res.profile_id);
          history.push({
            pathname: "/courses",
          });
        } else {
          document.getElementById("confirm").innerHTML = "Next";
        }
      });
    } else {
      alert(
        "Überprüfen Sie, ob das Formular vollständig ausgefüllt ist und ob das Foto im rechten Bereich ordentlich angezeigt wird."
      );
    }
  };

  const setEmailWithValidation = (val) => {
    var isValid = validator.validate(val);
    if (isValid) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
    setEmail(val);
  };
  useEffect(() => {
    toggleVisibility("photo_jumbotron");
    //toggleVisibility("dob_jumbotron");
  }, []);

  useLayoutEffect(() => {
    // Translations("", Language);
  }, []);
  return (
    <div>
      <div
        className="bg-mine"
        id="photo_jumbotron"
        style={{ marginBottom: "70px" }}
      >
        <h1 className="display-4" id="title">
          {Language[language].title}
        </h1>
        <p className="lead" id="subtitle">
          {Language[language].subtitle}
        </p>
        <hr className="my-4" />

        <div className="margin_top"></div>

        <button
          name="next_field"
          //className="btn btn-outline-dark"
          className="btn-round"
          type="button"
          onClick={() => toggleVisibility("firstname_jumbotron")}
        >
          {Language[language].button_next}{" "}
          <i className="fa fa-step-forward icon-border" aria-hidden="true"></i>
        </button>
      </div>

      <div className="bg-mine" id="firstname_jumbotron">
        <span id="username_tag">{Language[language].username_tag}</span>
        <div className="input-group mb-3">
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            className="form-control input"
            placeholder={Language[language].username_tag}
            id="username"
            required
          />
        </div>
        <small className="form-text text-muted" id="username_muted">
          {Language[language].username_muted}
        </small>
        <div className="margin_top"></div>
        <div className="d-flex">
          <button
            name="previous_field"
            //className="btn btn-outline-dark"
            className="btn-round"
            type="button"
            onClick={() => toggleVisibility("photo_jumbotron")}
          >
            <i
              className="fa fa-step-backward icon-border"
              aria-hidden="true"
            ></i>{" "}
            {Language[language].button_previous}
          </button>
          {firstName === "" ? (
            <></>
          ) : (
            <button
              name="next_field"
              //className="btn btn-outline-dark"
              className="btn-round margin-left-auto"
              type="button"
              onClick={() => {
                toggleVisibility("lastname_jumbotron");
              }}
            >
              {Language[language].button_next}{" "}
              <i
                className="fa fa-step-forward icon-border"
                aria-hidden="true"
              ></i>
            </button>
          )}
        </div>
      </div>

      <div className="bg-mine" id="lastname_jumbotron">
        <span id="usersurname_tag">{Language[language].usersurname_tag}</span>
        <div className="input-group mb-3">
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            className="form-control input"
            placeholder={Language[language].usersurname_tag}
            id="usersurname"
            required
          />
        </div>
        <small className="form-text text-muted" id="usersurname_muted">
          {Language[language].usersurname_muted}
        </small>
        <div className="margin_top"></div>
        <div className="d-flex">
          <button
            name="previous_field"
            //className="btn btn-outline-dark"
            className="btn-round"
            type="button"
            onClick={() => toggleVisibility("firstname_jumbotron")}
          >
            <i
              className="fa fa-step-backward icon-border"
              aria-hidden="true"
            ></i>{" "}
            {Language[language].button_previous}
          </button>

          {lastName === "" ? (
            <></>
          ) : (
            <button
              name="next_field"
              //className="btn btn-outline-dark"
              className="btn-round margin-left-auto "
              type="button"
              onClick={() => toggleVisibility("company_jumbotron")}
            >
              {Language[language].button_next}{" "}
              <i
                className="fa fa-step-forward icon-border"
                aria-hidden="true"
              ></i>
            </button>
          )}
        </div>
      </div>

      <div className="bg-mine" id="company_jumbotron">
        <span id="usercompany_tag">{Language[language].usercompany_tag}</span>
        <div className="input-group mb-3">
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            type="text"
            className="form-control input"
            placeholder={Language[language].usercompany_tag}
            id="usercompany"
          />
        </div>
        <small className="form-text text-muted" id="usercompany_muted">
          {Language[language].usercompany_muted}
        </small>
        <div className="margin_top"></div>
        <div className="d-flex">
          <button
            name="previous_field"
            //className="btn btn-outline-dark"
            className="btn-round"
            type="button"
            onClick={() => toggleVisibility("lastname_jumbotron")}
          >
            <i
              className="fa fa-step-backward icon-border"
              aria-hidden="true"
            ></i>{" "}
            {Language[language].button_previous}
          </button>
          {company === "" ? (
            <></>
          ) : (
            <button
              name="next_field"
              //className="btn btn-outline-dark"
              className="btn-round margin-left-auto"
              type="button"
              onClick={() => toggleVisibility("email_jumbotron")}
            >
              {Language[language].button_next}{" "}
              <i
                className="fa fa-step-forward icon-border"
                aria-hidden="true"
              ></i>
            </button>
          )}
        </div>
      </div>

      <div className="bg-mine" id="email_jumbotron">
        <span id="email_tag">{Language[language].email_tag}</span>
        <div className="input-group mb-3">
          <input
            value={email}
            onChange={(e) => {
              setEmailWithValidation(e.target.value);
              // setEmail(e.target.value);
            }}
            type="email"
            className="form-control input"
            placeholder={Language[language].email_tag}
            id="usercontact"
          />
        </div>
        <small className="form-text text-muted" id="email_muted">
          {Language[language].email_muted}
        </small>
        <div className="margin_top"></div>
        <div className="d-flex">
          <button
            name="previous_field"
            //className="btn btn-outline-dark"
            className="btn-round"
            type="button"
            onClick={() => toggleVisibility("company_jumbotron")}
          >
            <i
              className="fa fa-step-backward icon-border"
              aria-hidden="true"
            ></i>{" "}
            {Language[language].button_previous}
          </button>
          {!emailIsValid ? (
            <></>
          ) : (
            <button
              name="next_field"
              //className="btn btn-outline-dark"
              className="btn-round margin-left-auto"
              type="button"
              onClick={() => toggleVisibility("dob_jumbotron")}
            >
              {Language[language].button_next}{" "}
              <i
                className="fa fa-step-forward icon-border"
                aria-hidden="true"
              ></i>
            </button>
          )}
        </div>
      </div>

      <div className="bg-mine" id="dob_jumbotron">
        <span id="dob_tag">{Language[language].dob_tag}</span>
        <div className="input-group mb-3">
          <input
            onClick={createCalendar}
            type="text"
            id="userdob"
            className="form-control input"
            placeholder={Language[language].dob_tag}
            required
          />
          <input hidden type="text" id="userdob_val" />
        </div>
        <small className="form-text text-muted" id="dob_muted">
          {Language[language].dob_muted}
        </small>
        <div className="margin_top"></div>

        <div class="d-flex bd-highlight mb-3">
          <div class="p-2 bd-highlight">
            <button
              name="previous_field"
              //className="btn btn-outline-dark"
              className="btn-round"
              type="button"
              onClick={() => toggleVisibility("email_jumbotron")}
            >
              <i
                className="fa fa-step-backward icon-border"
                aria-hidden="true"
              ></i>{" "}
              {Language[language].button_previous}
            </button>
          </div>
          <div class="ms-auto p-2 bd-highlight">
            <button
              type="button"
              className="btn-round margin_left"
              onClick={storeData}
              id="confirm"
            >
              {Language[language].button_start_training}{" "}
              <i class="fa fa-forward icon-border" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="bg-mine" id="datepicker_jumbotron">
        <div className="row" style={{ padding: "20px" }}>
          <div className="col">
            <div className="row" id="day_picker_label">
              Day:
            </div>
            <div className="row">
              <select
                className="btn btn-outline-dark"
                id="day_setter"
                onChange={(e) => setDayVal(e.currentTarget.value)}
              >
                <option selected disabled></option>
              </select>
            </div>
          </div>

          <div
            className="col"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <div className="row" id="month_picker_label">
              month
            </div>
            <div className="row">
              <select
                className="btn btn-outline-dark"
                id="month_setter"
                onChange={(e) => onMonthChange(e.currentTarget.value)}
              >
                <option selected disabled></option>
              </select>
            </div>
          </div>

          <div className="col">
            <div className="row" id="year_picker_label">
              year
            </div>
            <div className="row">
              <select
                className="btn btn-outline-dark"
                id="year_setter"
                onChange={(e) => setYearVal(e.currentTarget.value)}
              ></select>
            </div>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={set_date}
            //className="btn btn-outline-dark col-1"
            className="btn-round"
          >
            <i class="fa fa-save icon-border" aria-hidden="true"></i>{" "}
            {Language[language].button_save_dob}
          </button>
        </div>
        {/* <div className="row" style={{ padding: "20px" }}>
          <div className="col-11"></div>
          <div className="col-1">
            <button
              type="button"
              onClick={set_date}
              //className="btn btn-outline-dark col-1"
              className="btn-smol"
            >
              OK
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const videoConstraints = {
  facingMode: "user",
};
export default Userdata;
