export default {
  submit: {
    en: "Submit test",
    de: "Test einreichen",
    ru: "Отправить тест",
  },
  repeat: {
    en: "Repeat",
    de: "Wiederholen",
    ru: "Повторите",
  },
  button_next: {
    en: "Next step",
    de: "Zum nächsten Schritt",
    ru: "К следующему шагу",
  },
};
