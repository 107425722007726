import React, { useEffect, useState } from "react";
import Quiz from "../quiz/Quiz";
import Training from "../training/Training";
import { useHistory } from "react-router-dom";
import useAuth from "../../utils/customHooks/useAuth";
import { Endpoints } from "../../repository/Endpoints";
import { PostMaker } from "../../repository/RequestMaker";
import { Spinner } from "react-bootstrap";

const Trainer = () => {
  useAuth();
  const history = useHistory();
  const [material, setMaterial] = useState({});
  const [current_material, setCurrent_material] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [materialChangerToggle, setMaterialChangerToggle] = useState(false);
  const [loadingGroupTraining, setLoadingGroupTraining] = useState(false);

  useEffect(() => {
    checkMaterialLength();
    const _current_material = localStorage.getItem("current_material");
    const _material = JSON.parse(localStorage.getItem("material"));
    console.log(_material[_current_material]);
    setMaterial(_material[_current_material]);
    setCurrent_material(_current_material);
  }, [materialChangerToggle]);

  useEffect(() => {
    if (!material) {
      history.push({
        pathname: "/courses",
      });
      return;
    }
    if (Object.keys(material).length === 0) {
      return;
    }
    setMaterialType(material.material_type.const);
  }, [material]);

  useEffect(() => {
    const trainingType = localStorage.getItem("trainingType");
    const _material = JSON.parse(localStorage.getItem("material"));
    const _material_length = _material.length;
    if (trainingType === "grouptraining") {
      setLoadingGroupTraining(true);
      grouptrainingMaterialSelector(_material, _material_length);
    }
  }, []);

  const materialChanger = () => {
    checkMaterialLength();
    localStorage.setItem("current_material", Number(current_material) + 1);
    setMaterialChangerToggle(!materialChangerToggle);
  };

  const checkMaterialLength = () => {
    if (
      Number(current_material) + 1 >=
      JSON.parse(localStorage.getItem("material")).length
    ) {
      console.log("all material done");
      localStorage.setItem("current_material", Number(current_material) + 1);
      history.push({
        pathname: "/certificate",
      });
      return;
    }
  };

  const renderTraining = (type) => {
    if (type === "VIDEO_STANDALONE") {
      return (
        <Training
          vidUrl={material.public_video_url}
          materialChanger={materialChanger}
          material_id={material.id}
        />
      );
    } else if (type === "TEST") {
      return (
        <Quiz
          material_id={material.id}
          material_name={material.name}
          materialChanger={materialChanger}
        />
      );
    }
  };

  const grouptrainingMaterialSelector = (_material, _material_length) => {
    for (let i = 0; i < _material.length; i++) {
      const material_one = _material[i];
      const type = material_one.material_type.const;
      if (type === "VIDEO_STANDALONE" || type === "VIDEO_WITH_SUBTITLE") {
        PostMaker(
          {
            section_material_id: material_one.id,
            course_enrollment_id: localStorage.getItem("enrollment_id"),
          },
          Endpoints.material_progress
        ).then((response) => {
          localStorage.setItem(
            "response" + Math.random(),
            JSON.stringify(response)
          );
          setTimeout(() => {}, 200);
        });
      } else {
        setMaterial(_material[i]);
        setCurrent_material(i);
        setMaterialType("TEST");
        setLoadingGroupTraining(false);
      }
    }
  };

  return (
    <>
      {loadingGroupTraining ? (
        <Spinner animation="grow" />
      ) : (
        renderTraining(materialType)
      )}
    </>
  );
};

export default Trainer;
